<template>
  <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu">
      <!-- Sidebar -->
      <div
        id="sidebar-container"
        class="col-md-2 border-right sidebar-expanded sidebar-fixed d-none d-block"
      >
        <!-- Media top -->
        <div class="media d-none d-md-block">
          <div class="card-body px-0 px-sm-3 pb-2">
            <img
              src="../../assets/img/avatar5.png"
              class="
                align-self-start
                float-left float-sm-none
                rounded-circle
                img-fluid
                mr-2
                w-25
              "
            />
            <div class="media-body mt-4 mt-sm-2">
              <p class="mb-0">Nama Lengkap User</p>
              <p class="card-text mb-1 small">Administrator</p>
            </div>
          </div>
        </div>

        <button
          class="btn btn-outline-secondary ml-3 mt-3 d-md-none"
          data-toggle="collapse"
          data-target="#submenu"
        >
          Submenu
        </button>
        <ul
          id="submenu"
          class="
            list-group list-group-flush
            border-top border-bottom
            py-2
            collapse-sm collapse
          "
        >
          <li class="list-group-item border-0 p-0 active">
            <router-link
              to="/admin/dashboard"
              class="d-block bg-hover py-1 pl-3"
              >Dasbor</router-link
            >
          </li>
          <li class="list-group-item border-0 p-0">
            <router-link
              to="/admin/notifikasi"
              class="d-block bg-hover py-1 pl-3"
              >Notifikasi</router-link
            >
          </li>
        </ul>
      </div>
      <!-- sidebar -->

      <!-- MAIN -->
      <div id="main-content" class="col-md-10 with-fixed-sidebar">
        <div class="row">
          <nav aria-label="breadcrumb" class="w-100">
            <ol class="breadcrumb mb-0 rounded-0 bg-transparent">
              <li class="breadcrumb-item small">
                <a href="./admin-dasbor.html">Administrasi</a>
              </li>
              <li class="breadcrumb-item small active" aria-current="page">
                Dasbor
              </li>
            </ol>
          </nav>
        </div>

        <div class="h4">Dasbor</div>

        <div class="row">
          <div class="col-12">
            <div
              class="alert alert-secondary alert-dismissible fade show"
              role="alert"
            >
              <button type="button" class="close" data-dismiss="alert">
                &times;
              </button>
              <strong>Alert!</strong> You should
              <a
                href="javascript:void(0);"
                class="alert-link"
                title="Click to go to this message"
                >read this message</a
              >.
              <button
                id="submitButton"
                class="btn btn-sm btn-light border ml-10 py-0 mt-n1"
                type="submit"
                title="Action..."
              >
                <i class="fa fa-user mr-1"></i>Action
              </button>
            </div>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <div class="row mt-3">
          <div class="col-12 col-sm-6">
            <h5>Line Chart</h5>
            <vue3-chart-js
              ref="myChart1Ref"
              :id="state.myChart1.id"
              :type="state.myChart1.type"
              :data="state.myChart1.data"
              :options="state.myChart1.options"
            ></vue3-chart-js>
            <p>
              Sunt in culpa qui officia deserunt mollit anim id est laborum
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco.
            </p>
          </div>
          <div class="col-12 col-sm-6">
            <h5>Bar Chart</h5>
            <vue3-chart-js
              ref="myChart2Ref"
              :id="state.myChart2.id"
              :type="state.myChart2.type"
              :data="state.myChart2.data"
              :options="state.myChart2.options"
            ></vue3-chart-js>
            <p>
              Sunt in culpa qui officia deserunt mollit anim id est laborum
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco.
            </p>
          </div>
        </div>
      </div>
      <!-- main-content -->
    </div>
    <!-- .row -->
  </div>
</template>
<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { reactive, ref } from "vue";

export default {
  setup() {
    const myChart1Ref = ref(null);
    const myChart2Ref = ref(null);
    const state = reactive({
      myChart1: {
        id: "myChart1",
        type: "line",
        data: {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
          ],
          datasets: [
            {
              label: "My First dataset",
              backgroundColor: "rgb(255, 99, 132)",
              borderColor: "rgb(255, 99, 132)",
              data: [0, 10, 5, 2, 20, 30, 45],
            },
          ],
        },
        options: {},
      },
      myChart2: {
        id: "myChart2",
        type: "bar",
        data: {
          labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
          datasets: [
            {
              label: "# of Votes",
              data: [12, 19, 3, 5, 2, 3],
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      },
    });
    return {
      state,
      myChart1Ref,
      myChart2Ref,
    };
  },
  components: {
    Vue3ChartJs,
  },
};
</script>